<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="64"
    width="64"
    viewBox="0 0 64 64"
    fill="currentColor"
    stroke-width="0px"
  >
    <path
      d="M53.77002,29.96729c-.92334,0-1.83594-.24805-2.63916-.7168-8.04541-4.72314-17.91748-5.72559-24.78369-5.73486h-.09863c-8.37646,0-14.76855,1.46094-14.83203,1.47559-2.8208.64502-5.63037-1.10791-6.28027-3.90527-.65137-2.80371,1.09814-5.6167,3.90039-6.271.30908-.07178,7.64258-1.75049,17.21191-1.75049h.11133c8.16309.01172,20.00146,1.26514,30.06006,7.17041,2.48438,1.45898,3.31885,4.66748,1.86035,7.15137-.93506,1.59229-2.66309,2.58105-4.51025,2.58105ZM26.24854,19.51562h.10156c7.36328.01025,17.98779,1.1084,26.80225,6.2832.57471.33545,1.34521.12939,1.67871-.43799.3418-.58252.146-1.33447-.43652-1.67676-9.28564-5.45166-20.37207-6.60889-28.0376-6.61963h-.1084c-9.11572,0-16.01514,1.5791-16.30469,1.64648-.6543.15283-1.06396.81201-.91113,1.46973.15234.65625.81934,1.06836,1.47803.91455.0249-.00635,6.87061-1.57959,15.73779-1.57959Z"
    />
    <path
      d="M49.38818,41.25879c-.89502,0-1.76807-.25098-2.52441-.7251-5.69141-3.54688-12.06543-5.16797-19.98633-5.09766-6.93701.06201-12.57031,1.39551-12.62451,1.40869-1.17578.33447-2.46289.18506-3.57568-.43604-1.11475-.62061-1.92041-1.63721-2.26807-2.86084-.34814-1.22705-.19775-2.51562.42432-3.62842.62158-1.1123,1.63916-1.91553,2.86475-2.26221.05225-.01514,6.04199-1.6167,14.6416-1.68848,10.08545-.07812,18.44971,2.03418,25.5752,6.47314,1.08008.67236,1.83447,1.72607,2.12305,2.96777.28906,1.24219.07617,2.52197-.59814,3.604-.87891,1.40625-2.39307,2.24512-4.05176,2.24512ZM27.37061,31.43359c8.49316,0,15.37256,1.81934,21.61377,5.7085.36133.22656.84473.10254,1.06201-.24609.146-.23438.12256-.46484.0957-.58105-.02686-.11572-.10693-.33154-.34082-.47705-6.46338-4.02637-14.12744-5.93555-23.42822-5.86914-7.86523.06543-13.53076,1.52148-13.58301,1.53662-.26953.07617-.40674.26123-.46484.36523s-.14404.31787-.06836.58447c.0752.26367.26221.40137.36719.45947.104.05811.31836.14404.58105.07031.03662-.01074,6.08545-1.48096,13.63672-1.54883.17676-.00146.35352-.00244.52881-.00244Z"
    />
    <path
      d="M44.90088,50.93555c-.87549,0-1.72314-.25684-2.45117-.74316-4.1333-2.75537-9.13184-4.09521-15.27979-4.09521h-.0083c-5.56641.00146-9.73193,1.3335-9.90625,1.39062-1.09326.37012-2.29004.28955-3.34766-.23535-1.06201-.52783-1.85352-1.43799-2.229-2.56299-.37354-1.12109-.2876-2.32031.24121-3.37646s1.43701-1.84229,2.55664-2.21387c.22461-.07471,5.60547-1.8374,12.68408-1.83887h.02588c8.04883,0,14.45752,1.77295,20.16748,5.5791,2.02637,1.35303,2.57568,4.10156,1.22607,6.12793-.82129,1.23242-2.19678,1.96826-3.6792,1.96826ZM27.16992,42.09717c6.96289,0,12.68701,1.55957,17.5,4.76807.07031.04688.14746.07031.23096.07031.06787,0,.23828-.01807.35059-.18652.12793-.19189.0752-.45361-.11768-.58252-5.02051-3.34668-10.7251-4.90625-17.94824-4.90625h-.02344c-6.43896.00146-11.22363,1.56787-11.42383,1.63477-.13574.04492-.20654.13818-.2417.20898-.03564.0708-.06836.18408-.02295.32031.0459.13818.1416.20996.21338.24561.06982.03369.17871.06543.30664.02197.52539-.1709,5.07959-1.59326,11.16699-1.59473h.00928Z"
    />
  </svg>
</template>
